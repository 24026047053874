<template>
  <sk-select :no-description="true"
             :default-value="jobsScopeDefaultValue"
             :preselected-value="enterpriseMemberJobsScope"
             :items-list="jobsScopeList"
             class="jobs-scope-select"
             @csvaluechanged="setScopeJobsSelect" />
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'enterpriseMemberJobsScope',
        'isEmailVerificationRequired',
        'userIsAdminOfEnterprise',
      ]),
      jobsScopeDefaultValue() { return this.$gettext('Select jobs scope'); },
      jobsScopeList() {
        if (this.isEmailVerificationRequired) {
          return [{id: 'participant_jobs', name: this.$gettext('My jobs')}];
        }

        const items = [
          {
            id: 'department_jobs',
            name: this.$gettext('Department(s) jobs')
          },
          {
            id: 'participant_jobs',
            name: this.$gettext('My jobs')
          }
        ];

        if (this.userIsAdminOfEnterprise) {
          items.unshift({
            id: 'all_jobs',
            name: this.$gettext('All jobs')
          });
        }

        return items;
      }
    },
    methods: {
      ...mapActions('UserInfoStore', ['updateJobsScope']),
      setScopeJobsSelect(value) {
        this.updateJobsScope(value);
      }
    }
  };
</script>

<style scoped>
  @media (min-width: 767px) {
    .jobs-scope-select {
      width: 150px;
    }
  }
</style>
